// <div data-controller="components--clipboard">
//     <div class="btn btn-primary"
//         id="copyAction"
//         data-target="components--clipboard.copyText"
//         data-clipboard-text="<%= "#{request.base_url}/#{current_user.username}" %>"
//         data-action="click->components--clipboard#onClickCopy">복사하기
//     </div>
// </div>
import {Controller} from "stimulus";
import ClipboardJS from 'clipboard';

export default class extends Controller {
  static targets = [ "copyText"];

  connect() {
    this.clipboard = new ClipboardJS(this.copyTextTarget);
  }

  disconnect() {
    this.clipboard.destroy()
  }
}