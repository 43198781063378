// <div data-controller="components--comments">
//
// </div>
import { Controller } from "stimulus";
import api from 'utils/api';
import helpers from 'utils/helpers';

export default class extends Controller {
  static targets = [ "messageInput", "commentsContainer" ];
  static values = {
    page: Number,
    filterApi: String,
    loading: Boolean,
    finished: Boolean,
  };

  scrollBottom() {
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
  }

  onScroll() {
    if (window.scrollY < 1000) {
      if (!this.loadingValue && !this.finishedValue) {
        this.loadComments();
      }
    }
  }

  loadComments() {
    this.loadingValue = true;
    const currentPage = this.hasPageValue ? this.pageValue : 1;
    const nextPage = currentPage + 1;

    api.get(`${this.filterApiValue}`, {
      page: nextPage, ...this.paramsValue
    }).then((res) => {
      const responseDoc = new DOMParser().parseFromString(res.data, "text/html");
      const renderedComments = responseDoc.getElementById("preRenderedComments").innerHTML;
      this.commentsContainerTarget.insertAdjacentHTML('afterbegin', renderedComments);
      this.pageValue = nextPage;
      this.loadingValue = false;
      this.finishedValue = renderedComments.trim() === "";
    })
  }

  connect() {
    setTimeout(() => {
      this.scrollBottom();
      this.messageInputTarget.focus();
    }, 5);
  }
}