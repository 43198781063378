// <div data-controller="components--select">
//
// </div>
import { Controller } from "stimulus";
import api from 'utils/api';
import helpers from 'utils/helpers';

export default class extends Controller {
  static targets = [ "form", "remoteSubmit" ];

  triggerSubmit(e) {
    this.formTarget.submit();
  }

  connect() {
  }
}