// <div data-controller="components--editor-js" id="editorContainer">
//
// </div>
import {Controller} from "stimulus";
import api from 'utils/api';
import * as helpers from 'utils/helpers';
import {DirectUpload} from "@rails/activestorage"
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Quote from "@editorjs/quote"
import Delimiter from '@editorjs/delimiter';
import List from '@editorjs/list';
import ImageTool from '@editorjs/image';
import Table from '@editorjs/table';
import Marker from '@editorjs/marker';
import Underline from '@editorjs/underline';
import Undo from 'editorjs-undo';
import DragDrop from 'editorjs-drag-drop';
import LinkTool from '@editorjs/link';
import Paragraph from 'editorjs-paragraph-with-alignment';
import CodeTool from "@editorjs/code";
import Embed from "@editorjs/embed";

Delimiter.prototype.drawView = function() {
  let div = document.createElement('DIV');

  div.classList.add(this._CSS.wrapper, this._CSS.block);
  div.innerHTML = "<hr>";

  return div;
};

export default class extends Controller {
  static targets = [
    "editorForm", "editorData"
  ];

  initialize() {
    this.editor = null;
  }

  submitForm() {
    this.editor.save().then((outputData) => {
      this.editorDataTarget.value = JSON.stringify(outputData);
      this.editorFormTarget.submit();
    }).catch((error) => {
      alert(error.response.data.error);
    });
  }

  connect() {
    let editorJsController = this;
    const defaultData = JSON.parse(`${this.data.get("default-data")}`);

    this.editor = new EditorJS({
      onReady: () => {
        const undo = new Undo({ editor: this.editor });
        undo.initialize(defaultData);
        new DragDrop(this.editor);
      },
      logLevel: process.env.NODE_ENV === 'production' ? 'ERROR' : 'ERROR',
      data: defaultData,
      holder: this.data.get("editor-container") || "editorContainer",
      placeholder: "Type..",
      tools: {
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          config: {
            preserveBlank: true,
          }
        },
        header: {
          class: Header,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+H',
          config: {
            levels: [2,3,4],
            defaultLevel: 2,
            placeholder: 'Type..',
            blockTuneDisplayDefault: true,
          },
        },
        linkTool: {
          class: LinkTool,
          shortcut: 'CMD+L',
          config: {
            endpoint: '/api/links/parse',
          }
        },
        list: {
          class: List,
          shortcut: 'CMD+SHIFT+L',
          inlineToolbar: true,
          config: {
            blockTuneDisplayDefault: true,
          }
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
          shortcut: 'CMD+SHIFT+O',
          config: {
            quotePlaceholder: 'Type..',
            captionPlaceholder: '\t'
          },
        },
        code: {
          class: CodeTool,
          shortcut: 'CMD+SHIFT+B'
        },
        underline: {
          class: Underline,
          inlineToolbar: true,
          shortcut: "CMD+U"
        },
        delimiter: {
          class: Delimiter,
          shortcut: 'CMD+SHIFT+D',
        },
        image: {
          class: ImageTool,
          shortcut: 'CMD+SHIFT+I',
          config: {
            captionPlaceholder: "\t",
            blockTuneDisplayDefault: true,
            uploader: {
              uploadByFile(file) {
                let uploadConfig = {};

                // 디지털 오션에서만 필요. aws 는 storage.yml 설정으로 다 먹힘
                uploadConfig = {
                  directUploadWillStoreFileWithXHR: (xhr) => {
                    xhr.setRequestHeader('x-amz-acl', 'public-read');
                  }
                };

                const upload = new DirectUpload(file, "/rails/active_storage/direct_uploads", uploadConfig);


                const uploadPromise = new Promise((resolve, reject) => {
                  if (file.size > 15000000) {
                    alert("File size too large. It should be smaller than 15mb");
                    reject();
                  }

                  upload.create((error, blob) => {
                    if (error) {
                      reject(error);
                    } else {
                      resolve(blob)
                    }
                  });
                });

                return uploadPromise.then((blob) => {
                  return {
                    success: 1,
                    file: {
                      url: `${editorJsController.data.get("cdn-host")}/${blob.key}`,
                      // any other image data you want to store, such as width, height, color, extension, etc
                    }
                  }
                });
              },
              async uploadByUrl(url){
                const response = await fetch(url);
                const blob = await response.blob();
                const file = new File([blob], url.split("/").pop());

                let uploadConfig = {};

                // 디지털 오션에서만 필요. aws 는 storage.yml 설정으로 다 먹힘
                uploadConfig = {
                  directUploadWillStoreFileWithXHR: (xhr) => {
                    xhr.setRequestHeader('x-amz-acl', 'public-read');
                  }
                };

                const upload = new DirectUpload(file, "/rails/active_storage/direct_uploads", uploadConfig);

                const uploadPromise = new Promise((resolve, reject) => {
                  if (file.size > 15000000) {
                    alert("File size too large. It should be smaller than 15mb");
                    reject();
                  }

                  upload.create((error, blob) => {
                    if (error) {
                      reject(error);
                    } else {
                      resolve(blob)
                    }
                  });
                });

                return uploadPromise.then((blob) => {
                  return {
                    success: 1,
                    file: {
                      url: `${editorJsController.data.get("cdn-host")}/${blob.key}`,
                      // any other image data you want to store, such as width, height, color, extension, etc
                    }
                  }
                });
              }
            }
          }
        },
        table: {
          class: Table,
          shortcut: 'CMD+SHIFT+E',
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 2,
          },
        },
        Marker: {
          class: Marker,
          shortcut: 'CMD+M',
        },
        embed: {
          class: Embed,
          config: {
            services: {
              youtube: true
            }
          }
        },
      },
    });

    this.autoSaver = setInterval(() => {
      this.autoSave();
    }, 1000);
  }

  autoSave() {
    if (this.editor) {
      this.editor.save().then((outputData) => {
        this.editorDataTarget.value = JSON.stringify(outputData);
      });
    }
  }

  disconnect() {
    if (this.editor) {
      this.editor.destroy();
    }
  }
}