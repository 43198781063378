// <div data-controller="components--orders">
//
// </div>
import { Controller } from "stimulus";
import api from 'utils/api';
import helpers from 'utils/helpers';

export default class extends Controller {
  static targets = [ "" ];

  connect() {
  }
}